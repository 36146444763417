import type { PolymorphicProps } from '#pie/constants/interfaces';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import type { ElementType, ReactNode } from 'react';
import { cn, tv } from '#pie/utils/TailwindUtils';

export type CardProps<TComponent extends ElementType = 'section'> = {
  heading?: ReactNode;
  children: ReactNode;
  className?: string;
} & PolymorphicProps<TComponent, VariantProps<typeof styles>>;

export function Card<TComponent extends ElementType = 'section'>({
  children,
  className,
  heading,
  shadow = true,
  size,
}: CardProps<TComponent>) {
  const s = styles({ shadow, size });
  return (
    <section className={cn(s.base(), className)}>
      {heading && <header className={s.heading()}>{heading}</header>}
      {children}
    </section>
  );
}

const styles = tv({
  base: 'rounded-lg border border-neutral-300 bg-white',
  slots: {
    heading: 'border-b border-neutral-300',
  },
  variants: {
    shadow: {
      true: {
        base: 'shadow',
      },
    },
    size: {
      md: {
        base: 'p-6',
        heading: '-mt-6 py-4 mb-4',
      },
    },
  },
});
