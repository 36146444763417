import { useOidc } from '@axa-fr/react-oidc';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ErrorPage } from '../error-page/ErrorPage';
import { Text } from '#pie/components/text/Text';
import { VITE_MOCK_IDP } from '#pie/env';

export const AuthError = () => {
  const oidc = useOidc();
  const [params] = useSearchParams();
  const isEherkenning = params.get('eh') === 'true';
  const { t } = useTranslation();

  useEffect(() => {
    if (isEherkenning) {
      oidc.login('/', { prompt: 'login' });
    }
  }, [isEherkenning, oidc.login]);

  return (
    <ErrorPage
      heading={t('auth_error.title')}
      message={
        <>
          {t('auth_error.message')}
          {VITE_MOCK_IDP && process.env.NODE_ENV === 'development' && (
            <>
              <Text>
                <code>VITE_MOCK_IDP</code> is <code>true</code>, so mock IDP server should be running.
              </Text>
              <Text>
                Did you run <code>npm run mock:idp</code>?
              </Text>
            </>
          )}
        </>
      }
    />
  );
};
