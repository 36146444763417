import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContactPersonRegisterContact } from './Contact';
import { ContactPersonRegisterContactGroup } from './ContactGroup';
import { ContactManagement } from './ContactManagement';
import { ContactPersonRegisterOrganisation } from './Organisation';
import { OrganisationContact } from './OrganisationContact';
import { useUserHasRole } from '#pie/auth/useUserHasRole';
import { CreateContactModal } from '#pie/components/contact-modal/CreateContactModal';
import { CprContactExport } from '#pie/components/cpr-export/CprContactExport';
import { CprOrganisationExport } from '#pie/components/cpr-export/CprOrganisationExport';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '#pie/components/tabs';

enum CprTabs {
  Contact = 'contact',
  Organisation = 'organisatie',
  ContactGroup = 'contactgroep',
  ContactManagement = 'contactgroepenbeheer',
  OrganisationContact = 'contactpersonenbeheer',
}

export const ContactPersonRegister = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const tab = location.pathname.split('/').at(2) as CprTabs;
  const hasCprAdminRole = useUserHasRole({ role: 'CprAdmin' });

  return (
    <>
      <PageHead title={t('cpr_overview.page_title')} />
      <Page>
        <PageHeader title={t('cpr_overview.page_title')} className="mb-8">
          {tab === CprTabs.Contact && <CprContactExport />}
          {tab === CprTabs.Organisation && <CprOrganisationExport />}
          {tab === CprTabs.OrganisationContact && <CreateContactModal />}
        </PageHeader>
        <section className="flex items-start gap-6">
          <Tabs asChild value={tab} onValueChange={val => navigate(`../${val}`)}>
            <Stack gap="lg" className="w-full">
              <TabsList>
                <TabsTrigger value={CprTabs.Contact}>{t('cpr_overview.tab.contact')}</TabsTrigger>
                <TabsTrigger value={CprTabs.Organisation}>{t('cpr_overview.tab.organisation')}</TabsTrigger>
                <TabsTrigger value={CprTabs.ContactGroup}>{t('cpr_overview.tab.contact-group')}</TabsTrigger>
                {hasCprAdminRole && (
                  <TabsTrigger value={CprTabs.ContactManagement}>
                    {t('cpr_overview.tab.contact-management')}
                  </TabsTrigger>
                )}
                <TabsTrigger value={CprTabs.OrganisationContact}>
                  {t('cpr_overview.tab.organisation_contact')}
                </TabsTrigger>
              </TabsList>
              <TabsContent value={CprTabs.Contact}>
                <ContactPersonRegisterContact />
              </TabsContent>
              <TabsContent value={CprTabs.Organisation}>
                <ContactPersonRegisterOrganisation />
              </TabsContent>
              <TabsContent value={CprTabs.ContactGroup}>
                <ContactPersonRegisterContactGroup />
              </TabsContent>
              {hasCprAdminRole && (
                <TabsContent value={CprTabs.ContactManagement}>
                  <ContactManagement />
                </TabsContent>
              )}
              <TabsContent value={CprTabs.OrganisationContact}>
                <OrganisationContact />
              </TabsContent>
            </Stack>
          </Tabs>
        </section>
      </Page>
    </>
  );
};
