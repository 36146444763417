import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useGetMainCategory, usePostTicket } from '#edsn/api/pie-bff';
import { TicketAssigneeCard, assigneeValidationSchema } from '../TicketAssigneeCard';
import { TicketAttachmentFields, attachmentValidationSchema } from '../TicketAttachmentFields';
import { TicketCategoriesFields, categoriesValidationSchema } from '../TicketCategoriesFields';
import { TicketGridOperatorsFields, gridOperatorValidationSchema } from '../TicketGridOperatorsFields';
import { TicketTemplateDialog } from '../TicketTemplateDialog';
import { useTemplate } from '../useTemplate';
import type { SubmitHandler } from 'react-hook-form';
import { Button } from '#pie/components/button/Button';
import { CancelCreateTicketDialog } from '#pie/components/cancel-create-ticket-dialog/CancelCreateTicketDialog';
import { Card } from '#pie/components/card/Card';
import { Divider } from '#pie/components/divider/Divider';
import { validation } from '#pie/components/form/formHelpers';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { RelatedKnowledgeArticlesCard } from '#pie/components/related-knowledge-articles-card/RelatedKnowledgeArticlesCard';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { ToastButton } from '#pie/components/toast/Toast';
import { useToast } from '#pie/components/toast/ToastContext';
import { useRelatedKnowledgeArticles } from '#pie/hooks/useRelatedKnowlegdeArticles';
import { useStoredParams } from '#pie/stores/searchParams';
import { isValidXmlCharacters } from '#pie/utils/isValidXmlCharacters';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const createTicketOtherSchema = ({
  t,
  categoryIdKV,
}: {
  categoryIdKV?: string;
} & Pick<ReturnType<typeof useTranslation>, 't'>) =>
  z
    .object({
      createAnother: z.array(z.string()).optional(),
      description: z
        .string()
        .max(2000)
        .optional()
        .refine(value => isValidXmlCharacters(value), {
          message: t('zod.errors.invalid_xml_characters'),
        }),
      eventDate: z.string().optional(),
      title: z.string().min(1).max(100),
    })
    .merge(gridOperatorValidationSchema)
    .merge(categoriesValidationSchema)
    .merge(assigneeValidationSchema)
    .merge(attachmentValidationSchema(t))
    .refine(check => check.mainCategoryId !== categoryIdKV || check.eventDate, {
      message: t('zod.errors.invalid_event_date'),
      path: ['eventDate'],
    });

type TicketOtherForm = z.infer<ReturnType<typeof createTicketOtherSchema>>;

const { FormCheckbox, FormDate, FormText, FormTextArea } = typedFormFields<TicketOtherForm>();

export const TicketCreateOtherDetails: React.FC = () => {
  const { t } = useTranslation();
  const params = useStoredParams('/tickets');

  const navigate = useNavigate();
  const { addToast } = useToast();

  const { data: categoryData } = useGetMainCategory();

  const categoryIdKV = useMemo(
    () => categoryData?.items.find(cat => cat.name === 'KV Disputen')?.id,
    [categoryData?.items]
  );

  const schema = useMemo(() => createTicketOtherSchema({ categoryIdKV, t }), [categoryIdKV]);

  const formMethods = useForm<TicketOtherForm>({
    resolver: zodResolver(schema),
  });
  const {
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = formMethods;

  const title = watch('title');
  const category = watch('mainCategoryId');
  const subCategory = watch('subCategoryId');
  const shouldShowEventDate = category === categoryIdKV;

  const { showConfirm, onConfirmClick, closeConfirm } = useTemplate<typeof schema>({
    getValues,
    setValue,
    watch,
  });

  useEffect(() => {
    if (!shouldShowEventDate) {
      reset({
        ...getValues(),
        eventDate: undefined,
      });
    }
  }, [getValues, reset, shouldShowEventDate]);

  const { mutate, isLoading, isSuccess } = usePostTicket({
    mutation: {
      onError() {
        addToast({
          message: t('ticket_create.toast.create_error.title'),
          title: t('ticket_create.toast.create_error.message'),
          type: 'error',
        });
      },
      onSuccess: ({ id }) => {
        addToast({
          action: (
            <ToastButton as={Link} to={`/tickets/${id}`}>
              {t('ticket_create.toast.create_success.action')}
            </ToastButton>
          ),
          message: t('ticket_create.toast.create_success.message', { title }),
          title: t('ticket_create.toast.create_success.title'),
          type: 'success',
        });
      },
    },
  });

  const onSubmit: SubmitHandler<TicketOtherForm> = ({ createAnother, files, eventDate, ...ticketData }) => {
    mutate({
      data: {
        ...ticketData,
        attachments: files?.filter(({ file }) => file).map(({ file }) => file),
        eventDate: eventDate?.toString(),
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(getValues('createAnother')?.includes('another') ? '/tickets/nieuw' : '/tickets');
    }
  }, [getValues, isSuccess, navigate]);

  const { relatedArticles, relatedArticlesLoading } = useRelatedKnowledgeArticles(category, subCategory);

  return (
    <>
      <PageHead title={t('ticket_create.page_title')} />
      <Page>
        <PageHeader title={t('ticket_create.other.page_title')} backHref="/tickets/nieuw" previousText="Vorige stap" />
        <TicketTemplateDialog closeConfirm={closeConfirm} onConfirmClick={onConfirmClick} showConfirm={showConfirm} />
        <FormProvider {...formMethods}>
          <form className="flex flex-col items-start gap-6 pb-8 md:flex-row" onSubmit={handleSubmit(onSubmit)}>
            <Card className="flex-[2] p-6" shadow>
              <Stack gap="lg">
                <Stack gap="md">
                  <Text as="h2" variant="h5">
                    {t('ticket_create.heading.ticket_about')}
                  </Text>
                  <TicketGridOperatorsFields />
                  <TicketCategoriesFields />
                  {shouldShowEventDate && (
                    <FormDate
                      label={t('common.event_date')}
                      fullWidth
                      name="eventDate"
                      rules={{ required: validation.required }}
                    />
                  )}
                </Stack>
                <Stack gap="md">
                  <Text as="h2" variant="h5">
                    {t('ticket_create.heading.details')}
                  </Text>
                  <FormText
                    label={t('common.subject')}
                    fullWidth
                    name="title"
                    rules={{ required: validation.required }}
                    showWordCount={100}
                  />
                  <FormTextArea
                    label={t('common.description')}
                    fullWidth
                    name="description"
                    rows={8}
                    showWordCount
                    maxLength={2000}
                  />
                  <TicketAttachmentFields />
                </Stack>
                <Divider />
                <Stack direction="row" className="justify-between" gap="lg">
                  <Button as={Link} to={`/tickets${params}`} variant="ghost">
                    {t('ticket_create.footer.cancel')}
                  </Button>
                  <Stack direction="row" className="items-center" gap="lg">
                    <FormCheckbox
                      name="createAnother"
                      options={[
                        {
                          label: t('ticket_create.footer.create_another'),
                          value: 'another',
                        },
                      ]}
                    />
                    <Button type="submit" variant="secondary" isLoading={isLoading}>
                      {t('ticket_create.footer.submit')}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
            <Stack as="aside" gap="lg" className="flex-1">
              <TicketAssigneeCard />
              {category && (
                <RelatedKnowledgeArticlesCard articles={relatedArticles} isLoading={relatedArticlesLoading} />
              )}
            </Stack>
          </form>
        </FormProvider>
        <CancelCreateTicketDialog shouldBlock={isDirty && !isSuccess} />
      </Page>
    </>
  );
};
